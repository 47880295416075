export default [{
    path: '/admin/customers',
    name: 'admin-customers',
    component: () =>
        import ('@/views/admin/customers/Customers.vue'),
    meta: {
        resource: 'customers.view',
        action: 'read',
        pageTitle: 'Clientes',
        breadcrumb: [{
                text: 'Administración',
                active: true,
            },
            {
                text: 'Clientes',
                active: true,
            }
        ],
    },
}, 
{
    path: '/admin/customers/search',
    name: 'customers.search',
    component: () => import('@/views/admin/customers/SearchMedicamento.vue'),
    meta: {
        resource: 'customers.search',
        action: 'read',
        pageTitle: 'Farmacia',
        breadcrumb: [{
            text: 'Administración',
            active: true,
        },
        {
            text: 'Busqueda',
            active: true,
        }
    ],
    },
},]