import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { BootstrapVue, IconsPlugin, ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import * as VueGoogleMaps from 'vue2-google-maps';
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/acl'
import '@/libs/sweet-alerts'
import InstantSearch from 'vue-instantsearch';
import VueHead from 'vue-head'

//styles
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

//Bootstrap Vue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


import VueFileAgent from 'vue-file-agent';

Vue.use(VueFileAgent);


Vue.use(InstantSearch);
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueHead)
// Composition API
Vue.use(VueCompositionAPI)

// Configuración de Vue Google Maps
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCHNPouaDl0dqNLD6ZBgi0wte3c75U6ZWI',
    libraries: 'places, drawing',
  },
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
