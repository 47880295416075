export default [
    {
        path: '/',
        name: 'guest',
        component: () => import('@/views/guest/User.vue'),
        meta: {
            layout: 'guest',
            resource: 'guest',
        },
    },
    {
        path: '/farmacia',
        name: 'guest_pharmacy',
        component: () => import('@/views/guest/Pharmacy.vue'),
        meta: {
            layout: 'guest',
            resource: 'guest',
        },
    },
]
