export default [
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/views/admin/users/Users.vue'),
    meta: {
      resource: 'users.view',
      action: 'read',
      pageTitle: 'Usuarios',
      breadcrumb: [
        {
          text: 'Usuarios',
          active: true,
        },
      ],
    },
  },
]
