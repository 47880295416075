import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import authentication from './routes/authentication'
import roles from './routes/roles'
import customers from './routes/customers'
import users from './routes/users'
import stock from './routes/stock'
import reports from './routes/reports'
import guest from './routes/guest'
import parametritation from './routes/parametritation'
import register from './routes/register-separate'
import deliveries from './routes/deliveries'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        ...guest,
        ...authentication,
        ...roles,
        ...users,
        ...customers,
        ...stock,
        ...reports,
        ...parametritation,
        ...register,
        ...deliveries,
        {
            path: '/admin',
            name: 'home',
            component: () =>
                import ('@/views/Home.vue'),
            meta: {
                resource: 'dashboard',
                action: 'read',
                pageTitle: 'Dashboard',
                breadcrumb: [{
                    text: 'Dashboard',
                    active: true,
                }, ],
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () =>
                import ('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()
    const urlGuest = ['guest'];

    if (urlGuest.includes(to.name)) return next();
    if (to.meta.validate){
        const response = to.meta.validate();

        if(response !== true){
            return next({ name: response || 'misc-not-authorized' })
        }
    }

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'auth-login' })

        // If logged in => not authorized
        return next({ name: 'misc-not-authorized' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
