export default [
      {
        path: '/admin/register/separate',
        name: 'admin-register-separate',
        component: () => import('@/views/admin/register/Separate.vue'),
        meta: {
          resource: 'users.view',
          action: 'read',
          pageTitle: 'Registro de separados'
        }
      },
      {
        path: '/admin/orders/:id',
        name: 'admin-orders',
        component: () => import('@/views/admin/register/Orders.vue'),
        meta: {
          resource: 'users.view',
          action: 'read',
          pageTitle: 'Pedidos'
        }
      }
      
    ]
    