export default [
      {
        path: '/admin/parametritation',
        name: 'admin-parametritation',
        component: () => import('@/views/admin/parametritation/Parametritation.vue'),
        meta: {
          resource: 'users.view',
          action: 'read',
          pageTitle: 'Parametrizacion'
        },
      },
      {
        path: '/admin/createParametritation/:id',
        name: 'admin-createParametritation',
        component: () => import('@/views/admin/parametritation/CreateParametritation.vue'),
        meta: {
          resource: 'users.view',
          action: 'read',
          pageTitle: 'Crear Parametrizacion'
        },
      },
    ]
    