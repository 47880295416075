export default [
    {
        path: '/stock/manage',
        name: 'stock-manage',
        component: () => import('@/views/stock/Stock.vue'),
        meta: {
            resource: 'stock.view',
            action: 'read',
            pageTitle: 'Gestionar inventario',
            breadcrumb: [
                {
                text: 'Inventario',
                active: true,
                },
                {
                text: 'Gestionar',
                active: true,
                },
            ],
        },
    },
    {
        path: '/stock/import',
        name: 'stock-masive',
        component: () => import('@/views/stock/StockImport.vue'),
        meta: {
            resource: 'stock_masive.view',
            action: 'read',
            pageTitle: 'Importar inventario',
            breadcrumb: [
                {
                text: 'Inventario',
                active: true,
                },
                {
                text: 'Importar',
                active: true,
                },
            ],
        },
    },
]