export default [
    {
        path: '/reports/stock',
        name: 'report-stock',
        component: () => import('@/views/reports/Stock.vue'),
        meta: {
            resource: 'report.stock',
            action: 'read',
            pageTitle: 'Reporte: Inventario',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true,
                },
                {
                    text: 'Inventario',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/reports/requested-notification',
        name: 'report-requested-notification',
        component: () => import('@/views/reports/RequestedNotification.vue'),
        meta: {
            resource: 'report.requested-notification',
            action: 'read',
            pageTitle: 'Reporte: Notificaciones solicitadas',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true,
                },
                {
                    text: 'Notificaciones solicitadas',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/reports/sent-notification',
        name: 'report-sent-notification',
        component: () => import('@/views/reports/SentNotification.vue'),
        meta: {
            resource: 'report.sent-notification',
            action: 'read',
            pageTitle: 'Reporte: Notificaciones enviadas (Webhooks)',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true,
                },
                {
                    text: 'Notificaciones enviadas',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/reports/medicine',
        name: 'report-medicine',
        component: () => import('@/views/reports/Medicine.vue'),
        meta: {
            resource: 'report.medicine',
            action: 'read',
            pageTitle: 'Reporte: Medicamentos',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true,
                },
                {
                    text: 'Medicamentos',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/reports/customers',
        name: 'report-customers',
        component: () => import('@/views/reports/Customers.vue'),
        meta: {
            resource: 'report.customers',
            action: 'read',
            pageTitle: 'Reporte: Clientes',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true,
                },
                {
                    text: 'Clientes',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/reports/users',
        name: 'report-users',
        component: () => import('@/views/reports/Users.vue'),
        meta: {
            resource: 'report.users',
            action: 'read',
            pageTitle: 'Reporte: Usuarios',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true,
                },
                {
                    text: 'Usuarios',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/reports/look_medicines',
        name: 'report-look-medicines',
        component: () => import('@/views/reports/LookForMedicines.vue'),
        meta: {
            resource: 'report.look_medicines',
            action: 'read',
            pageTitle: 'Reporte: Consulta de medicamentos',
            breadcrumb: [
                {
                    text: 'Reportes',
                    active: true,
                },
                {
                    text: 'Consulta de medicamentos',
                    active: true,
                },
            ],
        },
    }
]