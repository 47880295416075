import { createNamespacedHelpers } from "vuex";
import axios from "@axios";

const stateTemplate = () => ({
  person: {},
  cumStock: {},
  customer: {},
  medicalAuthorizations: [],
  customerShipmentType: {},
  shipmentInfo: null,
  maxWaitingDate: null,
  personLoadedFromDB: false,
  config: {},
  customerLocation: {
    customerMarker: null,
    distanceInKm: null, 
    calculatedCost: null,
    directions: null,
    selectedPlace: null,
  },
});
const delivery = localStorage.getItem("delivery");

export default {
  namespaced: true,
  state: Object.assign(stateTemplate(), delivery ? JSON.parse(delivery) : {}),

  mutations: {
    setPerson(state, person) {
      const schema = [
        "id",
        "documentType",
        "documentAcronym",
        "documentNumber",
        "names",
        "surnames",
        "email",
        "phone",
      ];

      const newPerson = schema.reduce((acc, key) => {
        acc[key] = person[key] === undefined ? state.person[key] : person[key];
        return acc;
      }, {});

      state.person = newPerson;
    },

    setCustomerShipmentType(state, customerShipmentType) {
      if (customerShipmentType) {
        const schema = [
          "id",
          "shipmentTypeId",
          "name",
          "group",
          "settingsType",
        ];

        state.customerShipmentType = schema.reduce((acc, key) => {
          acc[key] = customerShipmentType[key];
          return acc;
        }, {});

        state.config = {
          shipmentType: state.customerShipmentType.id,
          shipmentGroup: state.customerShipmentType.group,
        };
      } else {
        state.customerShipmentType = null;
      }

      state.shipmentInfo = null;
      state.shipmentSchedule = null;
    },

    setCumStock(state, { stock_id, amount, cum }) {
      const newStock = { ...state.cumStock };
      if (amount > 0) {
        newStock[stock_id] = { stock_id, amount, cum };
      } else {
        delete newStock[stock_id];
      }
      state.cumStock = newStock;
    },

    increaseCumStock(state, { stock_id, increase }) {
      const newStock = { ...state.cumStock };
      const { amount } = newStock[stock_id] ?? 0;

      //Si intenta incrementar de forma negativa, validar que no se vuelva negativo
      if (increase > 0 || amount > 0) {
        newStock[stock_id].amount += increase;
      }
      state.cumStock = newStock;
    },

    setCustomer(state, customer) {
      state.customer = customer;
    },

    setShipmentInfo(state, shipmentInfo) {
      const schema = {
        maps: ["id"],
        basic: [
          "id",
          "place",
          "address",
          "price",
          "minimumRequests",
          "schedules",
        ],
      };

      if (!shipmentInfo) {
        state.shipmentInfo = null;
        return;
      }

      state.shipmentInfo = schema[
        state.customerShipmentType.settingsType
      ].reduce((acc, key) => {
        acc[key] = shipmentInfo[key];
        return acc;
      }, {});
    },

    setMaxWaitingDate(state, maxWaitingDate) {
      state.maxWaitingDate = maxWaitingDate;
    },

    setPersonLoadedFromDB(state, value) {
      state.personLoadedFromDB = value;
    },

    setMedicalAuthorizations(state, value) {
      state.medicalAuthorizations = value;
    },

    setCustomerLocation(state, payload) {
      state.customerLocation = {
        ...state.customerLocation,
        customerMarker: payload.customerMarker,
        distanceInKm: payload.distanceInKm,
        calculatedCost: payload.calculatedCost,
        directions: payload.directions,
        selectedPlace: payload.selectedPlace,
      };

      localStorage.setItem('delivery', JSON.stringify(state));
    },
  },

  actions: {
    //Busca una persona por su número de documento, si existe la almacena en el state
    async searchPerson({ commit, state }) {
      const { documentNumber, documentType } = state.person;
      if (!documentNumber || !documentType) return;

      await axios
        .post("/people/search-by-document", { documentNumber, documentType })
        .then(({ data: { person } }) => {
          if (person) {
            commit("setPerson", { ...state.person, ...person });
            commit("setPersonLoadedFromDB", true);
          }
        })
        .catch(() => {
          const regex = new RegExp(/\*/g);

          const loadedKeys = Object.keys(state.person).filter((key) =>
            regex.test(state.person[key])
          );

          const resetPerson = loadedKeys.reduce(
            (acc, key) => {
              acc[key] = null;
              return acc;
            },
            {
              ...state.person,
              id: null,
            }
          );

          commit("setPerson", { ...resetPerson });
          commit("setPersonLoadedFromDB", false);
        });
    },

    // Actualiza la información del tipo de envío seleccionado
    updateShipmentInfo({ commit }, shipmentInfo) {
      commit("setShipmentInfo", shipmentInfo);
    },

    // Actualiza la información del tipo de envío seleccionado
    updateCustomerShipmentType({ commit }, customerShipmentType) {
      commit("setCustomerShipmentType", customerShipmentType);
      commit("setMaxWaitingDate", null);
    },

    //Guarda en el storage el estado actual del delivery
    saveInStorage({ state }) {
      localStorage.setItem("delivery", JSON.stringify(state));
    },

    //Borra el estado actual del delivery del storage
    clearStore({ state }) {
      Object.assign(state, stateTemplate());
      localStorage.removeItem("delivery");
    },

    // actualiza el medicalAuthorizations
    updateMedicalAuthorizations({ commit }) {
      commit("setMedicalAuthorizations", medicalAuthorizations);
    },

    // Guarda el pedido en la base de datos y retorna el id del pedido
    async saveInDB({ state }) {
      const url = "/deliveries/new";
      const data = state;

      return await axios
        .post(url, {
          person: data.person,
          maxWaitingDate: data.maxWaitingDate,
          cumStock: data.cumStock,
          customer:
            data.customer.id ??
            JSON.parse(localStorage.getItem("dataForm")).customer.id,
          medicalAuthorizations: data.medicalAuthorizations,
          customerLocation: data.customerLocation,
          customerShipmentType: data.customerShipmentType.id,
          shipmentInfo: data.shipmentInfo?.id,
          shipmentSchedule: data.shipmentSchedule?.id,
        })
        .then((response) => {

          if (response.status === 201) {
            return Promise.resolve(response.data);
          }
        })
        .catch((error) => {

          if (error.response.status === 422) {
            const errors = error.response.data.errors;
            const firstError = Object.values(errors)[0][0];

            return Promise.reject(firstError);
          } else {
            return Promise.reject(
              "El pedido no ha podido ser guardado debido a un error inesperado, por favor intenta nuevamente."
            );
          }
        });
    },
  },
};

export const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("deliveries");
