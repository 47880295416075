export default [
  {
    path: "/deliveries",
    name: "deliveries",
    component: () => import("@/views/deliveries/"),
    meta: {
      resource: "guest",
      layout: "guest",
      pageTitle: "Envíos",
      //validate(){
        // return localStorage.getItem('delivery') ? 'guest' : true ;
      //}
    },
  },
  {
    path: "/deliveries/:id",
    name: "public-deliveries-detail",
    component: () => import("@/views/deliveries/DeliveryDetail.vue"),
    meta: {
      resource: "guest",
      layout: "guest",
      pageTitle: "Entrega",
    },
  },
  {
    path: "/admin/deliveries",
    name: "admin-deliveries",
    component: () => import("@/views/admin/deliveries/Deliveries.vue"),
    meta: {
      resource: "deliveries.view",
      action: "read",
      pageTitle: "Entregas",
    },
  },
  {
    path: "/admin/deliveries/:id",
    name: "admin-deliveries-detail",
    component: () => import("@/views/admin/deliveries/DeliveryDetail.vue"),
    meta: {
      resource: "deliveries.view",
      action: "read",
      pageTitle: "",
      breadcrumb: [
        {
          text: 'Entregas',
          active: false,
          to: '/admin/deliveries',
        },
        {
          text: 'Detalle de entrega',
          active: true,
        }
      ],
    },
  },
];
